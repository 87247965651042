import { createSlice } from "@reduxjs/toolkit";

const itemMasterSlice = createSlice({
  name: "itemMasterItems",
  initialState: {file_name:"",items:[]},
  reducers: {
   
    getItemMasterItems() {},
    loadItemMasterItems(){},
    processAction(state, action) {
      //const itemsData = action.payload;

      //return { ...state, itemMaster: itemsData };
    },
    setItemMasterItems(state, action) {
      return { ...state, ...action.payload };
    },
    setItemMasterItem(state, action) {
      const itemData = action.payload;

      const item = [
        ...state.itemMasterItems.filter((d) => {
          if (d.item_id === itemData.item_id) {
            return false;
          } else {
            return true;
          }
        }),
        itemData,
      ];

      return { ...state, itemMaster: item };
    },
    viewItemMasterItems(){},
  },
});

export const { getItemMasterItems, loadItemMasterItems, processAction, setItemMasterItems, setItemMasterItem, viewItemMasterItems } = itemMasterSlice.actions;

export default itemMasterSlice.reducer;

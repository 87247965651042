/*!


*/

import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

// core components
//import PanelHeader from "components/PanelHeader/PanelHeader.js";

function Indicator() {
  const { isProcessing } = useSelector((state) => state.isProcessing);

  return (
    <>
      {isProcessing && (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={"Processing..."}
          onConfirm={() => () => {}}
          showConfirm={false} 
          confirmBtnBsStyle="info"
          btnSize=""
        >
          <Spinner className="text-primary"></Spinner>
        </ReactBSAlert>
      )}
    </>
  );
}

export default Indicator;

import { createSlice } from "@reduxjs/toolkit";

const indicatorSlice = createSlice({
  name: "isProcessing",

  initialState: { isProcessing: false },
  reducers: {
    setIndicator(state, action) {
     
      return { ...state, isProcessing: action.payload };
    },
  },
});

export const { setIndicator } = indicatorSlice.actions;

export default indicatorSlice.reducer;
